* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTimelineItem-root:before {
  display: none;
}


*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #C4C4C4;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #C4C4C4;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #02569D;
}

/* The marquee animation */
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}